<template>
  <div class="d-flex d-flex-r register-wrapper">
    <div class="d-flex d-flex-c flex-1" style="position: relative">
      <div class="d-flex d-flex-center">
        <div class="d-flex logo-wrapper">
          <a href="https://socianis.com">
            <img
              src="/assets/images/layout/logos/socianis_dark.svg"
              alt="socianis-logo"
            />
          </a>
        </div>
      </div>
      <div v-if="isVerification" class="d-flex d-flex-center flex-1">
        <social-auth-loader v-if="isVerifying" :labels="labels" />
        <div v-else class="d-flex d-flex-c">
          <div v-if="verifySuccess">
            <span> Your registration is complete.</span>
            <span>
              You can now login
              <router-link to="/login">Login here</router-link>
            </span>
          </div>
          <div v-else class="d-flex d-flex-c">
            <span class="mb-05">There was an issue verifying your email.</span>
          </div>
        </div>
      </div>
      <div v-else class="d-flex d-flex-center d-flex-c flex-1">
        <register-form :token="token" :label="verifySuccess ? '' : 'Sign up'" />
        <div class="login-section">
          Already have an account?
          <router-link to="/login">Login here</router-link>
        </div>
      </div>
    </div>
    <div class="register-bg flex-1" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { getUrlParam, removeUrlParams } from "@/utils/url";
import { RegisterForm, SocialAuthLoader } from "@/components/";

export default {
  name: "Register",
  components: { RegisterForm, SocialAuthLoader },
  data() {
    return {
      token: null,
      labels: ["Completing registration", "Please wait...."],
      verifySuccess: false,
    };
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
      isVerifying: (state) => state.auth.status.requesting.verifyRegistration,
    }),
    isVerification() {
      return this.$route.name === "verify";
    },
  },
  async created() {
    const params = getUrlParam(window.location.href);
    if (params.token) {
      this.token = params.token;
      if (this.isVerification) {
        const payload = { ...params };
        this.verifyRegistration(payload).then((res) => {
          if (this.isAuthenticated) {
            this.$router.push("/");
          } else {
            this.verifySuccess = true;
            removeUrlParams(...Object.keys(params));
          }
        });
      }
    }
  },
  methods: {
    ...mapActions({
      verifyRegistration: "verifyRegistration",
    }),
  },
};
</script>

<style lang="scss" scoped>
.register-wrapper {
  height: 100vh;

  .logo-wrapper {
    padding: 5em 1em 0;
    max-width: 450px;
    width: 100%;
    img {
      height: 50px;
    }
  }

  .register-form {
    max-width: 450px;
    width: 100%;
    padding: 1em;
  }
  .register-bg {
    display: none;
    width: 100%;
    background-image: url("/assets/images/layout/global/bg_register.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .login-section {
    width: 100%;
    max-width: 450px;
    padding: 1em;
  }
}

// Tablet portrait
@media only screen and (min-width: 1024px) {
  .register-wrapper {
    .register-bg {
      display: block;
    }
    .login-section {
      width: 100%;
      max-width: 450px;
    }
  }
}
</style>
